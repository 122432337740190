.login-counter{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    align-self: flex-end;
    border-radius: 50px;
    padding: 0px 10px;
    cursor: pointer;
    border: 2px solid var(--secondary-color);
    color: var(--secondary-color);
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.308);
}

@media (max-width: 768px) {
    .login-counter {
        width: 100%;
        justify-content: center;
        align-items: center;
        align-self: center;
        border-radius: 50px;
        padding: 0px 10px;
        cursor: pointer;
        border: 2px solid var(--secondary-color);
        color: var(--secondary-color);
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.308);
    }
}