.view-property-info h4{
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 0.5rem;
    
}

.view-property-info p{
    display: inline;
}

.view-property-info a{
    color:var(--primary-color);
    transition: 0.3s;
}

.view-property-info a:hover{
    color:var(--secondary-color);
    transition: 0.3s;
}